body {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000000;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.ui {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-family: "IM Fell French Canon", serif;
}

.quest-ui-layout {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quest-ui {
  background: rgba(1, 1, 1, 0.75);
  padding: 20px 20px;
  width: 700px;
  z-index: 2;
}

.quest-title {
  font-size: 3em;
  color: white;
  text-shadow: 4px 4px black;
}

.quest-text-title {
  font-size: 3em;
  color: white;
  padding-bottom: 10px;
}

.quest-text {
  font-size: 1em;
  color: white;
}

.quest-journal-layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.quest-journal {
  display: flex;
  flex-direction: column;
  background: rgba(1, 1, 1, 0.75);
  margin: 30px;
  padding: 20px 20px;
  padding-top: 5px;
  width: 300px;
  height: 600px;
  z-index: 1;
}

.quest-entry {
  font-size: 2em;
  color: white;
  border: black;
  border-style: solid;
  border-width: thick;
  padding: 5px;
}

.icon-ui {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.icon-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  z-index: 1;
}

.icon-bar-item {
  background-size: cover;
  width: 75px;
  height: 75px;
  margin: 2px;
}

.health-ui {
  background-image: url("./resources/icons/ui/health-bar.png");
  width: 500px;
  height: 300px;
  z-index: 1;
}

.health-bar {
  background: greenyellow;
  width: 200px;
  max-width: 200px;
  height: 40px;
  position: relative;
  top: 215px;
  left: 260px;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  border-radius: 5px;
}

.stats-tooltip {
  position: relative;
  display: inline-block;
  z-index: 100;
}

.stats-tooltip .stats-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  opacity: 0.75;
  text-align: center;
  padding: 1em;
  border-radius: 6px;
  color: white;
  font-size: medium;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.stats-tooltip:hover .stats-tooltiptext {
  visibility: visible;
}

.stats-title {
  font-size: 3em;
  color: white;
  text-shadow: 4px 4px black;
}

.stats {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 30px;
  z-index: 1;
}

.stats-inner {
  display: flex;
  flex-direction: column;
  background: rgba(1, 1, 1, 0.75);
  padding: 20px 20px;
  width: 250px;
  padding-top: 5px;
}

.stats-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 2em;
  color: white;
  text-shadow: 4px 4px black;
}

.inventory-title {
  font-size: 3em;
  color: white;
  text-shadow: 4px 4px black;
}

.inventory {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 30px;
  z-index: 1;
}

.inventory-inner {
  display: flex;
  flex-direction: column;
  background: rgba(1, 1, 1, 0.75);
  padding: 20px 20px;
  padding-top: 5px;
}

.inventory-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.inventory-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.inventory-character {
  background-image: url("./resources/icons/ui/inventory-character.png");
  background-size: cover;
  width: 200px;
  height: 350px;
}

.inventory-item {
  border: black;
  border-style: solid;
  border-radius: 10%;
  background-color: black;
  width: 50px;
  height: 50px;
  margin: 2px;
  background-size: cover;
}
